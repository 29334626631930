.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

/*  */
ul {
  list-style-type: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

/* li {
  display: inline-block;
} */

.my_input[type="checkbox"][id^="cb"] {
  display: none;
}

.my_label {
  border: 1px solid #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.my_label::before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

.my_label img {
  height: 100px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;

  transition: opacity ease .5s;
}

.my_label:hover img {
  opacity: .6;
}

.my_label i/* :hover */ {
  opacity: 0;
  color: blue;
  font-size: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.my_label:hover i {
  opacity: 1;
  -webkit-transition: opacity .25s ease-in-out .0s;
  transition: opacity .25s ease-in-out .0s;
}

:checked+.my_label {
  border-color: #ddd;
}

:checked+.my_label::before {
  content: "✓";
  background-color: #28a745;
  transform: scale(1);
}

:checked+.my_label img {
  transform: scale(0.9);
  box-shadow: 0 0 5px #333;
  z-index: -1;
}


